/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    highlighter: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M11.096.644a2 2 0 012.791.036l1.433 1.433a2 2 0 01.035 2.791l-.413.435-8.07 8.995a.5.5 0 01-.372.166h-3a.5.5 0 01-.234-.058l-.412.412A.5.5 0 012.5 15h-2a.5.5 0 01-.354-.854l1.412-1.412A.5.5 0 011.5 12.5v-3a.5.5 0 01.166-.372l8.995-8.07zm-.115 1.47L2.727 9.52l3.753 3.753 7.406-8.254zm3.585 2.17l.064-.068a1 1 0 00-.017-1.396L13.18 1.387a1 1 0 00-1.396-.018l-.068.065zM5.293 13.5L2.5 10.707v1.586L3.707 13.5z"/>',
    },
});
